<template>
  <div class="dailyWord_upload">
    <div class="mian">
      <div class="inner_nav clearfix">
        <div class="fl inner_nav_l">
          当前位置：<span>每日一字</span> >
          <router-link to="/dailyWord">每日一字上传</router-link> > 上传作品
        </div>
      </div>
      <div class="dailyWord_upload_box">
        <div class="dailyWord_upload_title">
          <span>作品信息</span>
          <i></i>
        </div>
        <div class="dailyWord_upload_name">
          <div class="dailyWord_upload_time">上传日期：{{ dataTime }}</div>
          <div class="dailyWord_upload_name_input">
            <input type="text" v-model="name" placeholder="请输入作品名称" />
          </div>
        </div>
        <div class="dailyWord_upload_title">
          <span>上传作品</span>
          <i></i>
        </div>
        <div
          :class="
            imageUrl
              ? 'dailyWord_upload_img dailyWord_upload_img2'
              : 'dailyWord_upload_img'
          "
        >
        <template v-if="headers.token">
          <img :src="imageUrl" v-if="imageUrl" />
          <el-upload
            class="upload-demo"
            :show-file-list="false"
            drag
            :headers="headers"
            :action="$api.url + '/api/' + $face.defaultUpload"
            :on-success="handleAvatarSuccess" 
            :before-upload="beforeAvatarUpload"
            accept=".png, .jpg, .jpeg, .gif"
          >
            <img v-if="imageUrl" :src="imageUrl" class="up_img" />
            <i class="upload_icon"></i>
            <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
            <div class="el-upload__tip">
              支持jpg、png、gif格式（建议尺寸：1200px * 1600px）3M以内
            </div>
          </el-upload>
          </template>
          <div class="upload-demo" v-else @click="tips">
            <div class="el-upload-dragger2">
              <img v-if="imageUrl" :src="imageUrl" class="up_img" />
              <i class="upload_icon"></i>
              <div class="el-upload__text">点击 / 拖拽图片文件到这里上传</div>
              <div class="el-upload__tip">
                支持jpg、png格式（建议尺寸：1000px * 660px）3M以内
              </div>
            </div>
          </div>
          <div class="dailyWord_upload_switch">
            <el-switch
              v-model="publicValue"
              :active-value="1"
              :inactive-value="0"
              active-color="#5957FF"
              inactive-color="#eeeeee"
            ></el-switch>
            公开
          </div>
        </div>

        <div class="upload_save" @click="saveFun">提交</div>
      </div>
    </div>
    <!-- 侧边导航 -->
    <!-- <sidebar ref="downtotal"></sidebar>  v-if="randomState"-->

  <successPop v-if="randomState" ref="success" type="wordUpload" :showarr="[false,false]"></successPop>
  





  </div>
</template>

<script>
// import { WOW } from 'wowjs'
import successPop from '../components/successPop.vue'
export default {
  name: "dailyWordUpload",
  data() {
    return {
      dataTime: "", //上传日期
      name: "", //作品名称
      headers: {
        token: localStorage.getItem("access_token"),
      },
      imageUrl: "", //上传图片路径
        userId:'',
      publicValue: 1,
      list:[],
      randomState:false,

      high:0
    };
  },
  components:{
    successPop
  },
  mounted() {
    this.$parent.routerIndex = 5;
    this.$parent.showIndex = 1;
    // new WOW().init();
    // 获取当前日期
    var aData = new Date();
    var month =
      aData.getMonth() < 9
        ? "0" + (aData.getMonth() + 1)
        : aData.getMonth() + 1;
    var date = aData.getDate() <= 9 ? "0" + aData.getDate() : aData.getDate();
    this.dataTime = aData.getFullYear() + "/" + month + "/" + date;


  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.$utile.prompt("success", "上传成功");
      this.imageUrl = res.initialPreview[0];
      this.high = res.initialPreviewConfig[0].high
    },
    beforeAvatarUpload(file) {
      console.log(file)
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 3;

      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$utile.prompt("error", "上传图片大小不能超过 3MB!");
      }
      return isLt2M;
    },
    tips(){
        this.$parent.loginShow();
        return;
    },
    saveFun() {
      if (!localStorage.getItem("access_token")) {
        this.$parent.loginShow();
        return;
      }

     
      var that = this;
      var params = {
        title: this.name,
        thumb: this.imageUrl,
        public: this.publicValue,
        high: this.high
      };
      this.$api.POST(this.$face.fontTaskUploads, params, function (res) {
        //that.$utile.prompt("success", "上传成功");
        that.name = "";
        that.imageUrl = "";
        that.publicValue = 1;
        document.body.scrollTop = 0;
        //that.$refs.success.randomFun();
        that.randomState=true;
      });
    },
    close(){
      this.randomState=false;
    },
    toVideo(){
      let routeData = this.$router.resolve({
        name: "video"
      });
      window.open(routeData.href, "_blank");
    },
    toVideoDetail(id){
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
 
  },
  watch: {
    '$store.state.userInfo'(newval){
        this.headers.token=localStorage.getItem("access_token");
    }
  },
  activated(){
    this.randomState=false;
  }
};
</script>

<style scoped>
.dailyWord_upload {
  background-color: #f6f6f6;
  padding-bottom: 35px;
}

.inner_nav {
  padding: 27px 0 17px;
}
.inner_nav_l {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}
.inner_nav_r {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}
.dailyWord_upload_box {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 39px 30px 26px;
  box-sizing: border-box;
}
.dailyWord_upload_title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #333333;
  padding-bottom: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #eeeeee;
  background: url(../assets/images/navbg.png) no-repeat;
  background-size: 35px 35px;
  background-position: -9px -8px;
  position: relative;
}
.dailyWord_upload_title span::after {
  content: "*";
  color: #e61f19;
  margin-left: 5px;
}
.dailyWord_upload_title i {
  display: block;
  width: 6px;
  height: 20px;
  background-color: #5957ff;
  position: absolute;
  left: 0;
  top: 0;
}

.dailyWord_upload_name {
  padding: 35px 0 68px 20px;
}
.dailyWord_upload_time {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 28px;
}
.dailyWord_upload_name_input {
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: solid 1px #eeeeee;
  padding: 0 20px;
  box-sizing: border-box;
}
.dailyWord_upload_name_input input {
  background: transparent;
  width: 100%;
  line-height: 48px;
}
.dailyWord_upload_img {
  margin: 18px 20px;
  box-sizing: border-box;
}
.dailyWord_upload_img >>> .upload-demo {
  width: 100%;
  height: 326px;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}
.dailyWord_upload_img >>> .el-upload-dragger {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
}
.dailyWord_upload_img .el-upload-dragger2 {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
  text-align: center;
  cursor: pointer;
}
.dailyWord_upload_img >>> .upload_icon {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img .upload_icon2 {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img >>> .el-upload__text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.dailyWord_upload_img .el-upload__text2 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}
.dailyWord_upload_img >>> .el-upload__tip {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
}
.dailyWord_upload_img .el-upload__tip2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999;
}
.dailyWord_upload_img >>> .up_img {
  max-width: 461px;
  max-height: 208px;
  margin: 0 auto;
}
.dailyWord_upload_img .up_img2 {
  max-width: 461px;
  max-height: 208px;
  margin: 0 auto;
}

.dailyWord_upload_switch {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333333;
}

.dailyWord_upload_switch >>> .el-switch__core {
  width: 57px !important;
  height: 29px;
  border-radius: 29px;
  margin-right: 15px;
}
.dailyWord_upload_switch >>> .el-switch__core:after {
  width: 25px;
  height: 25px;
}
.dailyWord_upload_switch >>> .el-switch.is-checked .el-switch__core::after {
  margin-left: -26px;
}

.upload_save {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #5957ff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 auto;
  cursor: pointer;
}

.sidebar_r {
  right: 2%;
  left: auto;
}
.dailyWord_upload_img2 {
  position: relative;
}
.dailyWord_upload_img2 >>> .el-upload {
  width: 100%;
  height: 326px;
}
.dailyWord_upload_img2 >>> .el-upload-dragger {
  width: 100%;
  height: 100%;
  opacity: 0;
}
.dailyWord_upload_img2 > img {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateX(-50%);
  height: 326px;
  width: auto;
}

</style>